import { Persistent, BasicKeys } from '@/utils/cache/persistent';
import { CacheTypeEnum, TOKEN_KEY } from '@/enums/cacheEnum';
import projectSetting from '@/settings/projectSetting';

const { permissionCacheType } = projectSetting;
const isLocal = permissionCacheType === CacheTypeEnum.LOCAL;

export function getToken() {
  return getAuthCache(TOKEN_KEY);
}

export function getAuthCache<T>(key: BasicKeys) {
  const fn = isLocal ? Persistent.getLocal : Persistent.getSession;
  return fn(key) as T;
}

export function setAuthCache(key: BasicKeys, value) {
  const fn = isLocal ? Persistent.setLocal : Persistent.setSession;
  return fn(key, value, true);
}

export function clearAuthCache(immediate = true) {
  const fn = isLocal ? Persistent.clearLocal : Persistent.clearSession;
  return fn(immediate);
}

export function transformAuthDataCarryGroup(data, groups, roots) {
  return data.map((item) => {
    const { permission, subList } = item;
    let menuName = '';
    let groupName = '';
    const groupNameArr: string[] = [];
    roots.forEach((item) => {
      if (item.permissionIds.includes(permission.permissionId)) {
        const curGroup = groups.find((g) => g.groupId === item.groupId);
        groupNameArr.push(curGroup.groupName);
        const str = groupNameArr.join('，');
        menuName = `${permission.name}`;
        groupName = `${str ? `（${str}）` : ''}`;
      }
    });
    const transformedItem = {
      menuName,
      groupName,
      id: permission.permissionId.toString(),
      iconMenu: permission.icon,
      component: permission.type === 'directory' ? 'LAYOUT' : '',
      permission: permission.mark,
      orderNo: permission.sort,
      status: permission.enableFlag ? '1' : '0',
      componentPath: permission.componentPath,
      ...permission,
      children: subList ? transformAuthDataCarryGroup(subList, groups, roots) : [],
    };
    return transformedItem;
  });
}
