<template>
  <div class="flex h-100%">
    <Menu
      class="h-100%"
      :class="`w-${menuWidth}px`"
      :items="menuTabsItems"
      @select="handleSelect"
      :selectedKeys="defaultSelectedKey"
    />
    <ScrollContainer class="mx-2">
      <slot></slot>
    </ScrollContainer>
  </div>
</template>

<script lang="ts" setup>
  import { ref, onMounted } from 'vue';
  import { Menu } from 'ant-design-vue';
  import { MenuTabsItemsType, ScrollContainer } from '@/components/Container';

  defineOptions({ name: 'MenuTabsContainer' });

  const props = defineProps({
    // 当前选中菜单
    activeKey: {
      type: [String, Number],
    },
    // 菜单数据
    menuTabsItems: {
      type: Array<MenuTabsItemsType>,
      default: [],
    },
    menuWidth: {
      type: Number,
      default: 180,
    },
  });

  const emit = defineEmits(['update:activeKey', 'change']);

  const defaultSelectedKey = ref<string[] | number[]>([]);

  // 初始化获取第一个菜单Key值
  const getDefaultKey = (menus: MenuTabsItemsType[] = props.menuTabsItems) => {
    const menuArray: any = [...menus];
    for (const menuItem of menuArray) {
      // 如果当前对象的type不为'group'，返回其key值
      if (menuItem?.type !== 'group') {
        return menuItem.key;
      }
      // 如果当前对象有children，则递归调用该函数来查找第一个非group的子项的key值
      if (menuItem.children) {
        const childKey = getDefaultKey(menuItem.children);
        if (childKey) {
          return childKey;
        }
      }
    }
    return null; // 如果没有找到非group的对象，则返回null
  };

  // 菜单选中
  function handleSelect(e) {
    const { key } = e;
    defaultSelectedKey.value = [key];
    emit('update:activeKey', key);
    emit('change');
  }

  onMounted(() => {
    const defaultKey = getDefaultKey();
    defaultSelectedKey.value = [defaultKey];
    emit('update:activeKey', defaultKey);
  });
</script>
<style lang="less"></style>
