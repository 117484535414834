import type { ProjectConfig } from '#/config';
import { MenuTypeEnum, MenuModeEnum, TriggerEnum, MixSidebarTriggerEnum } from '@/enums/menuEnum';
import { CacheTypeEnum } from '@/enums/cacheEnum';
import {
  ContentEnum,
  PermissionModeEnum,
  ThemeEnum,
  RouterTransitionEnum,
  SettingButtonPositionEnum,
  SessionTimeoutProcessingEnum,
} from '@/enums/appEnum';
import {
  SIDE_BAR_BG_COLOR_LIST,
  HEADER_PRESET_BG_COLOR_LIST,
  APP_PRESET_COLOR_LIST,
} from './designSetting';

// ! You need to clear the browser cache after the change
const setting: ProjectConfig = {
  // Whether to show the configuration button
  // 是否显示SettingButton
  showSettingButton: false,
  // 是否显示主题切换按钮
  // Whether to show the theme switch button
  showDarkModeToggle: true,
  // 设置按钮位置 可选项
  // SettingButtonPositionEnum.AUTO: 自动选择
  // SettingButtonPositionEnum.HEADER: 位于头部
  // SettingButtonPositionEnum.FIXED: 固定在右侧
  // `Settings` button position
  settingButtonPosition: SettingButtonPositionEnum.AUTO,
  // 权限模式,默认前端角色权限模式
  // ROUTE_MAPPING: 前端模式（菜单由路由生成，默认）
  // ROLE：前端模式（菜单路由分开）
  // BACK 路由模式
  // Permission mode
  permissionMode: PermissionModeEnum.BACK,
  // 权限缓存存放位置。默认存放于localStorage
  // Permission-related cache is stored in sessionStorage or localStorage
  permissionCacheType: CacheTypeEnum.LOCAL,
  // 会话超时处理方案
  // SessionTimeoutProcessingEnum.ROUTE_JUMP: 路由跳转到登录页
  // SessionTimeoutProcessingEnum.PAGE_COVERAGE: 生成登录弹窗，覆盖当前页面
  // Session timeout processing
  sessionTimeoutProcessing: SessionTimeoutProcessingEnum.ROUTE_JUMP,
  // 项目主题色
  // color
  themeColor: APP_PRESET_COLOR_LIST[0],
  // 网站灰色模式，用于可能悼念的日期开启
  // Website gray mode, open for possible mourning dates
  grayMode: false,

  // Color Weakness Mode
  // 色弱模式
  colorWeak: false,
  // 是否取消菜单,顶部,多标签页显示, 用于可能内嵌在别的系统内
  // Whether to cancel the menu, the top, the multi-tab page display, for possible embedded in other systems
  fullContent: false,

  // content mode
  // 主题内容宽度
  contentMode: ContentEnum.FULL,

  // Whether to display the logo
  // 是否显示logo
  showLogo: true,

  // Whether to show footer
  // 是否显示底部信息 copyright
  showFooter: false,

  // Header configuration
  // 头部配置
  headerSetting: {
    // header bg color
    bgColor: HEADER_PRESET_BG_COLOR_LIST[0],
    // Fixed at the top
    // 固定头部
    fixed: true,
    // Whether to show top
    show: true,
    // theme
    // 主题
    theme: ThemeEnum.LIGHT,
    // Whether to enable the lock screen function
    // 开启锁屏功能
    useLockPage: false,
    // Whether to show the full screen button
    // 显示全屏按钮
    showFullScreen: true,
    // Whether to show the document button
    // 显示文档按钮
    showDoc: false,
    // Whether to show the notification button
    // 显示消息中心按钮
    showNotice: false,
    // Whether to display the menu search
    // 显示菜单搜索按钮
    showSearch: true,
    //是否显示api控制弹框
    showApi: false,
  },

  // Menu configuration
  // 菜单配置
  menuSetting: {
    // sidebar menu bg color
    // 背景色
    bgColor: SIDE_BAR_BG_COLOR_LIST[0],
    //  Whether to fix the left menu
    // 是否固定住菜单
    fixed: true,
    // Menu collapse
    // 菜单折叠
    collapsed: false,
    // When sider hide because of the responsive layout
    siderHidden: false,
    // Whether to display the menu name when folding the menu
    // 折叠菜单时候是否显示菜单名
    collapsedShowTitle: false,
    // Whether it can be dragged
    // 是否可拖拽
    // Only limited to the opening of the left menu, the mouse has a drag bar on the right side of the menu
    canDrag: false,
    // 是否显示
    show: true,
    // Whether to show dom
    hidden: false,
    // 菜单宽度
    menuWidth: 210,
    // 菜单模式
    mode: MenuModeEnum.INLINE,
    // 菜单类型
    type: MenuTypeEnum.MIX,
    // 菜单主题
    theme: ThemeEnum.DARK,
    // 分割菜单
    split: true,
    // 顶部菜单布局
    topMenuAlign: 'center',
    // 折叠触发器的位置
    trigger: TriggerEnum.HEADER,
    // 手风琴模式，只展示一个菜单
    accordion: true,
    // 在路由切换的时候关闭左侧混合菜单展开菜单
    closeMixSidebarOnChange: false,
    // Module opening method ‘click’ |'hover' / 左侧混合菜单模块切换触发方式
    mixSideTrigger: MixSidebarTriggerEnum.CLICK,
    // 是否固定左侧混合菜单
    mixSideFixed: false,
  },

  // 多标签
  multiTabsSetting: {
    // 刷新后是否保留已经打开的标签页
    cache: false,
    // 开启
    show: true,
    // 是否可以拖拽
    canDrag: true,
    // 开启快速操作
    showQuick: true,
    // 是否显示刷新按钮
    showRedo: true,
    // 是否显示折叠按钮
    showFold: true,
    // Auto collapsed
    autoCollapse: false,
  },

  // 动画配置
  transitionSetting: {
    //  是否开启切换动画
    enable: true,

    // 动画名
    basicTransition: RouterTransitionEnum.FADE_SIDE,

    // 是否打开页面切换loading
    openPageLoading: true,

    // 是否打开页面切换顶部进度条
    openNProgress: false,
  },

  // 是否开启KeepAlive缓存  开发时候最好关闭,不然每次都需要清除缓存
  openKeepAlive: true,

  // 自动锁屏时间，为0不锁屏。 单位分钟 默认1个小时
  lockTime: 0,

  // 显示面包屑
  showBreadCrumb: true,

  // 显示面包屑图标
  showBreadCrumbIcon: false,

  // 是否使用全局错误捕获
  useErrorHandle: false,

  // 是否开启回到顶部
  useOpenBackTop: true,

  //  是否可以嵌入iframe页面
  canEmbedIFramePage: true,

  // 切换界面的时候是否删除未关闭的message及notify
  closeMessageOnSwitch: true,

  // 切换界面的时候是否取消已经发送但是未响应的http请求。
  // 如果开启,想对单独接口覆盖。可以在单独接口设置
  removeAllHttpPending: false,
};

export default setting;
