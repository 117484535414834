import { defHttp } from '@/utils/http/axios';
import {
  LoginParams,
  LoginResultModel,
  GetUserInfoModel,
  AccountChangePasswordModel,
  RefreshTokenModel,
} from './model/userModel';

import { ErrorMessageMode } from '#/axios';

enum Api {
  // Login = '/kpi/admin/sys/code/login',
  Login = '/api/auth/oauth2/official/doLogin',
  Logout = '/api/auth/oauth2/revokeToken',
  GetUserInfo = '/api/user/user/queryCurrentUserBasicInformation',
  GetPermCode = '/mock-api/getPermCode',
  TestRetry = '/mock-api/testRetry',
  RegCaptcha = '/api/admin/regCaptcha',
  postAccountChangePasswordApi = '/api/user/userAccount/official/officialAccountChangePassword',
  RefreshTokenApi = '/api/auth/oauth2/refreshToken',
}

/**
 * @description: 获取验证码
 */
export function getSmsCodeApi(params: Object, mode: ErrorMessageMode = 'modal') {
  return defHttp.post({ url: Api.RegCaptcha, params }, { errorMessageMode: mode });
}

/**
 * @description: user login api
 */
export function loginApi(params: LoginParams, mode: ErrorMessageMode = 'message') {
  return defHttp.post<LoginResultModel>(
    {
      url: Api.Login,
      params,
    },
    {
      errorMessageMode: mode,
    },
  );
}

/**
 * @description: getUserInfo
 */
export function getUserInfo() {
  return defHttp.get<GetUserInfoModel>({ url: Api.GetUserInfo }, { errorMessageMode: 'none' });
}

export function getPermCode() {
  return defHttp.get<string[]>({ url: Api.GetPermCode });
}

export function doLogout() {
  return defHttp.delete({ url: Api.Logout });
}

export function testRetry() {
  return defHttp.get(
    { url: Api.TestRetry },
    {
      retryRequest: {
        isOpenRetry: true,
        count: 5,
        waitTime: 1000,
      },
    },
  );
}

/**
 * @description: 修改密码
 */
export function postAccountChangePassword(
  params: AccountChangePasswordModel,
  mode: ErrorMessageMode = 'modal',
) {
  return defHttp.put<Object>(
    {
      url: Api.postAccountChangePasswordApi,
      params,
    },
    {
      errorMessageMode: mode,
    },
  );
}
/**
 * @description:刷新token
 */
export function postRefreshToken(params: RefreshTokenModel, mode: ErrorMessageMode = 'modal') {
  return defHttp.post<Object>(
    {
      url: Api.RefreshTokenApi,
      params,
    },
    {
      errorMessageMode: mode,
    },
  );
}
