import { defHttp } from '@/utils/http/axios';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getMenuListResultModel, RouteUserItemModel } from './model/menuModel';

enum Api {
  GetMenuList = '/mock-api/getMenuList',
  GetUserMenuList = '/api/auth/userRoleRoot/official/permissionInformationOfTheUser',
}

/**
 * @description: Get user menu based on id
 */

// export const getMenuList = () => {
//   return defHttp.get<getMenuListResultModel>({ url: Api.GetMenuList });
// };

export const getMenuList = () => {
  return defHttp.get<RouteUserItemModel>({ url: Api.GetUserMenuList });
};
