export const SIDE_BAR_MINI_WIDTH = 48;
export const SIDE_BAR_SHOW_TIT_MINI_WIDTH = 80;

export enum ContentEnum {
  // auto width
  FULL = 'full',
  // fixed width
  FIXED = 'fixed',
}

// menu theme enum
export enum ThemeEnum {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum SettingButtonPositionEnum {
  AUTO = 'auto',
  HEADER = 'header',
  FIXED = 'fixed',
}

export enum SessionTimeoutProcessingEnum {
  ROUTE_JUMP,
  PAGE_COVERAGE,
}

/**
 * 权限模式
 */
export enum PermissionModeEnum {
  // role
  // 角色权限
  ROLE = 'ROLE',
  // black
  // 后端
  BACK = 'BACK',
  // route mapping
  // 路由映射
  ROUTE_MAPPING = 'ROUTE_MAPPING',
}

// Route switching animation
// 路由切换动画
export enum RouterTransitionEnum {
  ZOOM_FADE = 'zoom-fade',
  ZOOM_OUT = 'zoom-out',
  FADE_SIDE = 'fade-slide',
  FADE = 'fade',
  FADE_BOTTOM = 'fade-bottom',
  FADE_SCALE = 'fade-scale',
}

/**
 * 支付方式
 */
export enum PayTypeEnum {
  WECHAT_PAY = 'WECHAT_PAY',
  ALI_PAY = 'ALI_PAY',
  BALANCE_PAY = 'BALANCE_PAY',
  WECHAT_TRANSFER = 'WECHAT_TRANSFER',
  ALI_TRANSFER = 'ALI_TRANSFER',
  BANK_CARD_TRANSFER = 'BANK_CARD_TRANSFER',
}
export enum PayTypeLabelEnum {
  WECHAT_PAY = '微信支付',
  ALI_PAY = '支付宝支付',
  BALANCE_PAY = '额度支付',
  WECHAT_TRANSFER = '微信转账',
  ALI_TRANSFER = '支付宝转账',
  BANK_CARD_TRANSFER = '对公打款',
}

/**
 * 增值服务类型
 */
export enum ServeTypeEnum {
  AR_GAME = 'AR_GAME',
  NFC_GAME = 'NFC_GAME',
}
export enum ServeTypeZhEnum {
  AR_GAME = 'AR',
  NFC_GAME = 'NFC',
}
/**
 * 小程序跳转类型
 */
export enum MiniProgramJumpTypeEnum {
  production = 'production',
  experience = 'experience',
  development = 'development',
}
export enum MiniProgramJumpTypeZhEnum {
  production = '正式版',
  experience = '体验版',
  development = '开发版',
}
/**
 * 归属类型
 */
export enum BelongTypeEnum {
  OFFICIAL = 'OFFICIAL', // ("官方"),
  ENTERPRISE = 'ENTERPRISE', //("企业端"),
  OFFICIAL_SAAS_CLIENT = 'OFFICIAL_SAAS_CLIENT', //("官方Saas客户端"),
  ENTERPRISE_SAAS_CLIENT = 'ENTERPRISE_SAAS_CLIENT', //("企业Saas客户端"),
  AGENT_CLIENT = 'AGENT_CLIENT', //("代理小程序端")
}
