/**
 * Independent time operation tool to facilitate subsequent switch to dayjs
 */
import dayjs, { Dayjs } from 'dayjs';

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DATE_FORMAT = 'YYYY-MM-DD';
/**
 * 将给定的日期和时间格式化为指定的字符串格式
 *
 * @param date - 一个可选的日期参数。可以是字符串、日期对象或数字（时间戳）。
 * 如果未提供此参数，则默认为当前日期和时间。
 * @param format - 一个可选的字符串格式参数，用于指定输出格式。默认使用预定义的 DATE_TIME_FORMAT。
 * @returns 返回一个字符串，表示格式化后的日期和时间。
 */
export function formatToDateTime(date?: dayjs.ConfigType, format = DATE_TIME_FORMAT): string {
  return date ? dayjs(date).format(format) : '--';
}
/**
 * 将给定的日期格式化为指定的字符串格式
 *
 * @param date - 一个可选的日期参数。可以是字符串、日期对象或数字（时间戳）。
 * 如果未提供此参数，则默认为当前日期。
 * @param format - 一个可选的字符串格式参数，用于指定输出格式。默认使用预定义的 DATE_FORMAT。
 * @returns 返回一个字符串，表示格式化后的日期。
 */
export function formatToDate(date?: dayjs.ConfigType, format = DATE_FORMAT): string {
  return date ? dayjs(date).format(format) : '--';
}
/**
 * 获取给定日期的当天起始时间
 *
 * @param date - 一个可选的日期参数。可以是字符串、日期对象或数字（时间戳）。
 *   如果未提供此参数，则默认为当前日期。
 * @returns 返回一个 Dayjs 对象，表示给定日期的当天起始时间。
 */
export function getStartOfDay(date?: dayjs.ConfigType): Dayjs {
  return dayjs(date).startOf('day');
}
/**
 * 获取给定日期的当天结束时间
 *
 * @param date - 一个可选的日期参数。可以是字符串、日期对象或数字（时间戳）。
 *   如果未提供此参数，则默认为当前日期。
 * @returns 返回一个 Dayjs 对象，表示给定日期的当天结束时间。
 */
export function getEndOfDay(date?: dayjs.ConfigType): Dayjs {
  return dayjs(date).endOf('day');
}
/**
 * 获取给定日期的当周起始时间
 *
 * @param date - 一个可选的日期参数。可以是字符串、日期对象或数字（时间戳）。
 *   如果未提供此参数，则默认为当前日期。
 * @returns 返回一个 Dayjs 对象，表示给定日期的当周起始时间。
 */
export function getStartOfWeek(date?: dayjs.ConfigType): Dayjs {
  return dayjs(date).startOf('week');
}
/**
 * 获取给定日期的当周结束时间
 *
 * @param date - 一个可选的日期参数。可以是字符串、日期对象或数字（时间戳）。
 *   如果未提供此参数，将会使用当前日期。
 * @returns 返回一个 Dayjs 对象，表示给定日期的当周结束时间。
 */
export function getEndOfWeek(date?: dayjs.ConfigType): Dayjs {
  return dayjs(date).endOf('week');
}
/**
 * 获取给定日期的当月起始时间
 *
 * @param date - 一个可选的日期参数。可以是字符串、日期对象或数字（时间戳）。
 *   如果未提供此参数，将会使用当前日期。
 * @returns 返回一个 Dayjs 对象，表示给定日期的当月起始时间。
 */
export function getStartOfMonth(date?: dayjs.ConfigType): Dayjs {
  return dayjs(date).startOf('month');
}
/**
 * 获取给定日期的当月结束时间
 *
 * @param date - 一个可选的日期参数。可以是字符串、日期对象或数字（时间戳）。
 *   如果未提供此参数，则默认为当前日期。
 * @returns 返回一个 Dayjs 对象，表示给定日期的当月结束时间。
 */
export function getEndOfMonth(date?: dayjs.ConfigType): Dayjs {
  return dayjs(date).endOf('month');
}
/**
 * 获得当天的23:59:59
 *
 * @param date - 日期参数
 * @returns 时间戳
 */
export function getLastSecond(date: dayjs.ConfigType): number {
  return dayjs(`${dayjs(date).format('YYYY-MM-DD')} 23:59:59`).unix();
}

export const dateUtil = dayjs;
